@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-image: url("./assets/background.png");
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.carousel-root {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.image-crisp {
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

.responsive-font-size {
  font-size: calc(1rem + 0.2vw);
  line-height: calc(1.5rem + 0.2vw);
}

.small-link {
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.text-dark.small-link:hover {
  color: #333333;
}

.text-light.small-link:hover {
  color: #fff;
}

.text-light strong {
  color: #fff;
}

@media (min-width: 768px) {
  .md\:text-light strong {
    color: #fff;
  }
}

.shadow-default {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.form-input-select:after {
  content: ">";
  font:
    17px "Consolas",
    monospace;
  color: #fff;
  position: absolute;
  pointer-events: none;
  right: 5px;
  top: 10px;
  transform: rotate(90deg);
}

input:focus {
  outline: none;
}

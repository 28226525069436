.textured-button-bg {
  background-image: var(--textured-button-bg);
}

.hover\:textured-button-bg:hover {
  background-image: var(--textured-button-bg-hover);
}

.active\:textured-button-bg:active {
  background-image: var(--textured-button-bg-active);
}

.form-input-fade-in {
  animation: form-input-fade-in 0.25s;
}

select:invalid { color: gray; }

@keyframes form-input-fade-in {
  from {
    height: 0px;
  }
  to {
    height: 36px;
  }
}
